@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

.admintext {
  font-family: "Figtree", sans-serif;
  font-optical-sizing: auto;
  color: #060606;
  font-size: 32px;
  font-weight: 800;
}

.admintextp {
  font-family: "Figtree", sans-serif;
  font-optical-sizing: auto;
  color: #a7a4a4;
  margin: 1px 0px 0px 0px !important;
  font-size: 14px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoin {
  color: #333333;
  font-weight: 700;
  font-family: "Figtree", sans-serif;
}

.logoimagereg {
  width: 100%;
  height: auto;
  max-width: 300px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .logoimagereg {
    width: 100%;
    height: auto;
    max-width: 250px;
  }
}
.backimagelogin{
  height: 100vh;
  background-image: url('../login//logo1.png');
  background-size: cover;
  background-repeat: no-repeat;
 
}

@media screen and (max-width: 998px) {
  .backimagelogin {
    display: none;
    height: 50vh;
  }
}

.registration-Loginin {
  width: 100%;
  max-width: 420px;
  margin: 50px auto;
  margin-top: 140px;
  padding: 20px;
  box-shadow: rgba(157, 153, 153, 0.15) 0px 5px 15px 0px;
  border-radius: 30px;
  background-color: white;
}

@media only screen and (max-width: 998px) {
  .registration-Loginin {
    width: 100%;
    max-width: 700px;
 padding: 10px;
    border-radius: 15px;
  }
}

@media only screen and (max-width: 568px) {
  .registration-Loginin {
    width: 100%;
    max-width: none;
    padding: 10px;
    margin-top: 120px;
    border-radius: 15px;
  }
}

.form-group {
  margin-bottom: 20px;
}

.reglable {
  font-family: "Figtree", sans-serif;
}

.password-input-container {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.eyepassword {
  color: rgb(127, 127, 127);
  font-size: 16px;
  cursor: pointer;
}

.reg-form {
  max-width: 100%;
  width: 100%;
  margin-bottom: 2px;
  padding: 11px;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-family: "Figtree", sans-serif;
}

.regformbtn1 {
  width: 100%;
  padding: 10px;
  background-color: #111111;
  border: none;
  border-radius: 27px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

@media only screen and (max-width: 678px) {
  .reg-form {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2px;
    padding: 9px;
    border: 1px solid #ccc;
    border-radius: 7px;
    font-family: "Figtree", sans-serif;
  }

  .regformbtn1 {
    width: 100%;
    padding: 9px;
    background-color: #111111;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
}
