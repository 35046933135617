@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

.earningnav{
 background-color: #ffffff;
 padding: 13px;
}
.earningnav1{
  background-color: #ffffff;
  padding: 12px;
 }

.iconbar{
 cursor: pointer;
 }

 .texnav{
  margin-right: 35px;
  font-weight: bold;
  font-family: 'Figtree', sans-serif;
  cursor: pointer;
 }

.top-logo {
    width: 280px;
    height: auto;
  }
  @media only screen and (max-width: 768px) {
    .top-logo {
      width: 200px;
      height: auto;
      margin-top: 0px;

    }
    .uldiv{
     margin-left: 50px;
     margin-top: 20px;
    }
  }
  .rightlogo{
    width: 30px;
    height: 30px;
  }
  .tabledivs{
    background-color:  #ffffff;
    border-radius: 15px;
    padding: 4px;
  }
 
 
  .log-outbtn {
    color: #FFC42B;
    background-color: transparent;
    border: 1px solid #FFC42B;
    border-radius: 6px;
    padding: 3px 10px;
    cursor: pointer;
    font-size: 15px;
    transition: all 0.3s ease;
}
.acceptedtext{
  text-decoration:none ;
  font-size: 18px;
}
.acceptdark{
  color: black;
}
.acceptdark:hover{
  color: rgb(255, 174, 0);
}
.Accepted {
    color: #ffffff;
    background-color: #FFC42B;
    border: 1px solid #FFC42B;
    border-radius: 6px;
    padding: 3px 10px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
}

@media (max-width: 768px) {
    .log-outbtn .Accepted {
        font-size: 14px;
        padding: 2px 8px;
    }
    
}

@media (max-width: 480px) {
    .log-outbtn, .Accepted {
        font-size: 12px;
        padding: 1px 6px;
    }
}



  .view{
    color: #ffffff;
    background-color: #f0bd27;
    border: none;
    border-radius: 8px;
    width: 90%;
    padding: 4px 15px;
    cursor: pointer;
  }
 
  .table-wrapper {
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.custom-table thead {
    background-color: #333;
    color: #fff;
    text-align:center;
    border-radius: 15px;
}

.custom-table th, .custom-table td {
    padding: 12px 15px;
}

.custom-table tbody tr {
    border-bottom: 1px solid #ddd;
}

.arrorleft-icon{
  font-size: 14px;
  cursor: pointer;
  padding-top: 7px;
  font-weight: 900;
}
.arroright-icon{
  font-size: 14px;
  cursor: pointer;
  padding-top: 7px;
  font-weight: 900;
}
.overlowrifht{
  margin-right: 10px;
}

.pdf-viewer {
  position: relative;
  height: 92vh;
  overflow: hidden;
  max-width: 100%;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.loading-indicator,
.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #333;
}

body {
  background-color: #e7e7e7;
}

.search-icon {
  margin-left: 10px;
  color: #504f4f;
}
.search-bar-input {
  width: 50%;
  padding: 6px;
  border: none;
  border-radius: 3px;
  margin-left: 8px;
  background-color: transparent;
  font-family: "Nunito Sans", sans-serif;
}

.search-bar-input:focus {
  width: 90%;
  padding: 5px;
  border: none;
  outline: none;
  border-radius: 3px;
  margin-left: 8px;
  font-family: "Nunito Sans", sans-serif;
}



.search-bar {
  display: flex;
  width: 30%;
    align-items: center;
    padding: 2px;
    border: 1px solid #ddd;
    border-radius: 30px;
    background-color:#ffffff;
    margin-left: 80px;
}

@media  screen and (max-width: 767px) {
  .search-bar-input {
    width: 90%;
    padding: 5px;
    border: none;
    border-radius: 3px;
    margin-left: 8px;
    background-color: transparent;
    
  }
  .search-bar {
    display: flex;
    width: 90%;
      align-items: center;
      padding: 2px;
      border: 1px solid #ddd;
      border-radius: 30px;
      background-color:#ffffff;
      margin-left: 20px;
  }
}






